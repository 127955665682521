export const tableColumn = [
	{
		label: '姓名',
		prop: 'username',
		width: '150px',

	},
	{
		label: '部门',
		prop: 'deptName',
	},
	{
		label: '新数据量',
		prop: 'newCustomerNum',
	},
	{
		label: '跟进客户量',
		prop: 'flowCustomerNum',
	},
	{
		label: '通次',
		prop: 'callNum',
	},
	{
		label: '通时(min)',
		prop: 'callDuration',
		width: '120px',
	}
]
